import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next/types';

type UIAnalyticType = {
	type: string;
	actionSubject: string;
	actionSubjectId: string;
	action: string;
	source: string;
	contentId?: string;
};

const COMPONENT_NAME = 'nudgeTooltip';

export const fireEvent = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	{ contentId, actionSubjectId, actionSubject, source, action, type }: UIAnalyticType,
) => {
	createAnalyticsEvent({
		type,
		data: {
			actionSubject,
			action,
			actionSubjectId,
			source,
			attributes: {
				...{ contentId },
				nudgeTarget: actionSubjectId,
				componentName: COMPONENT_NAME,
			},
		},
	}).fire();
};
